.picker-window {
    border-top: 1px solid #d8dada;
    border-bottom: 1px solid #d8dada;
    left: 0;
    top: 50%;
    transform: translateY(-65%);
    width: 100%;
    pointer-events: none;
  }
  
  /* .triangle {
    content: "";
    background: white;
    height: 20px;
    width: 20px;
    position: absolute;
    display: block;
    bottom: -40px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
  } */
  
  .picker {
    display: inline-grid;
    /* box-shadow: 0 0.5vw 2vw -5px rgba(0, 0, 0, 0.2); */
    border-radius: 15px;
    position: relative;
    /* border-top: 2rem solid white; */
    /* border-bottom: 2rem solid white; */
    padding-left: 1rem;
    padding-right: 1rem;
    /* background: white; */
    grid-template-columns: repeat(4, fit-content(500px));
    font-size: calc(0.4rem + 2.0vw);
    position: relative;
    font-feature-settings: "tnum";
    font-weight: 400;
}
  
  .picker:before,
  .picker:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2.2em;
    pointer-events: none;
  }
  
  .picker:before {
    top: -0.2em;
    /* background: linear-gradient(white, rgba(255, 255, 255, 0.7)); */
  }
  
  .picker:after {
    bottom: -0.2em;
    height: 2.4em;
    /* background: linear-gradient(rgba(255, 255, 255, 0.7), white); */
  }
  
  
  .picker ul {
    -ms-overflow-style: none;
  }
  
  .picker ul::-webkit-scrollbar {
    display: none;
  }
  .picker ul {
    max-height: 13em;
    overflow-y: scroll;
    margin-right: 16px;
    scroll-snap-type: y mandatory;
    padding-bottom: 3em;
    padding-top: 3em;
}
  
  .picker li {
    scroll-snap-align: center;
    height: 1.4em;
    text-align: right;
    word-spacing: 0.2em;
  }
  
  .picker-day {
    padding-right: 0.5em;
  }
  
  .picker-day span {
    padding-left: 0.56em;
  }
  
  .button-container {
    text-align: center;
    /* padding-top: 3rem; */
    width: 100%;
  }
  
  button {
    display: inline-block;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px solid #264f88;
    background: transparent;
    color: #264f88;
    border-radius: 15px;
    font-weight: 500;
    padding: 1rem;
    font-size: 1.1rem;
    font-family: "San Francisco Text", sans-serif;
    cursor: pointer;
    letter-spacing: 0.01em;
  }
  .picker li {
    height: 76px;
}

.ct_small_box_130,.ct_small_box_130 img{
    width: 80px !important;
    height: 80px !important;
}
.ct_small_num_box {
   
    margin-inline: auto;
}